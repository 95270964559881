export const hrefs = {
  pl: [
    {
      title: 'Kim jesteśmy',
      slug: '/kim-jestesmy',
    },
    {
      title: 'Jak pracujemy',
      slug: '/jak-pracujemy',
    },
    {
      title: 'Nasze projekty',
      slug: '/nasze-projekty',
    },
    {
      title: 'Case study',
      slug: '/case-study',
    },
    {
      title: 'Kontakt',
      slug: '/kontakt',
    },
  ],
  en: [
    {
      title: 'Who are we',
      slug: '/en/who-are-we',
    },
    {
      title: 'How we work',
      slug: '/en/how-we-work',
    },
    {
      title: 'Our projects',
      slug: '/en/our-projects',
    },
    {
      title: 'Case study',
      slug: '/en/case-study',
    },
    {
      title: 'Contact',
      slug: '/en/contact',
    },
  ],
};
