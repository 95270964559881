import React from "react"
import { IconWrapper, IconPath, IconRectStroke } from "shared/Styles/Icons"

const Facebook = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPath
      color={color}
      d="M23.7163 11.0672L20.9504 11C19.8865 11 18.8227 11.3358 18.0426 12.0746C17.1915 12.8134 16.766 13.8209 16.766 14.8284V17.3134H14.2128C14.0709 17.3806 14 17.4478 14 17.5149V20.6716C14 20.7388 14.0709 20.8731 14.2128 20.8731H16.766V28.7985C16.766 28.8657 16.8369 29 16.9787 29H20.383C20.4539 29 20.5957 28.9328 20.5957 28.7985V20.8731H23.2199C23.2908 20.8731 23.4326 20.806 23.4326 20.7388L23.8582 17.5821C23.8582 17.5149 23.8582 17.4478 23.7872 17.4478C23.7163 17.3806 23.7163 17.3806 23.6454 17.3806H20.5957V15.7687C20.5957 15.7687 20.5248 14.9627 20.9504 14.5597C21.1631 14.3582 21.4468 14.2239 21.8014 14.2239H23.7872C23.8582 14.2239 24 14.1567 24 14.0224V11.2687C23.9291 11.1343 23.8582 11.0672 23.7163 11.0672Z"
      fill="white"
    />
    <IconRectStroke
      color={color}
      x="1"
      y="1"
      width="38"
      height="38"
      rx="4"
      stroke="white"
      strokeWidth="2"
    />
  </IconWrapper>
)

export { Facebook }
