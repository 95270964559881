/*global google*/
import React, { Component } from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={16}
        initialCenter={{
          lat: 50.160283,
          lng: 21.537110,
        }}
      >
        <Marker
          name={"ArtProgres"}
          position={{ lat: 50.160283, lng: 21.537110 }}
          icon={{
            url: "/marker.png",
            anchor: new google.maps.Point(16, 40),
            scaledSize: new google.maps.Size(34, 50),
          }}
        />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: `AIzaSyAXfpQKq3raResEMnW2CFFizNGw5FBTioI`,
})(MapContainer)
