import React from "react"
import {
  IconWrapper,
  IconPathStroke,
  IconCircleStroke,
} from "shared/Styles/Icons"

const ArrowRightCircle = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      color={color}
      d="M14.1016 20.0678L19.5253 16L14.1016 11.9322"
      stroke="white"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconCircleStroke
      color={color}
      cx="16"
      cy="16"
      r="15"
      transform="rotate(-90 16 16)"
      stroke="white"
      strokeWidth="1"
    />
  </IconWrapper>
)

export { ArrowRightCircle }
