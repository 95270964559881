import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";

import "shared/Styles/global.css";
import "shared/Styles/reset.css";
import "shared/Styles/fonts.css";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([EffectFade, Navigation, Autoplay, Pagination]);

export { default as wrapRootElement } from "./src/store/index";
