import React from "react"
import { IconWrapper, IconPathStroke } from "shared/Styles/Icons"

const ArrowLong = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 30 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      color={color}
      d="M24 9L28 5L24 1"
      stroke="#ABC539"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <IconPathStroke
      color={color}
      d="M1 5L28 5"
      stroke="#ABC539"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </IconWrapper>
)

export { ArrowLong }
