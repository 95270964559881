import React, {useState} from 'react';
import {
  Hamburger,
  Logo,
  NavbarBox,
  NavbarWrapper,
  MenuItem,
  MenuLangBox,
  MenuLink,
  MenuList,
  MenuOptions,
  MenuSocials,
  MenuWrapper,
  StyledSocials,
} from './styles.js';

import LogoImage from '../../assets/logo.svg';
import FbLogo from '../../assets/fb.svg';
import LinkedLogo from '../../assets/linked.svg';
import {hrefs} from '../../data/navbar';
import {navigate} from 'gatsby';

import {useScrollPosition} from '../Hooks/useScrollPosition';
import {changeLocale} from '../../store/locale';
import {useDispatch, useSelector} from 'react-redux';

const langs = [
  {
    title: 'pl',
    slug: '/',
  },
  {
    title: 'en',
    slug: '/en',
  },
];

const socials = [
  {
    title: 'facebook',
    slug: 'https://www.facebook.com/Art-Progres-483804225063906',
    icon: FbLogo,
  },
  {
    title: 'LinkeIn',
    slug: 'https://www.linkedin.com/company/art-progres/',
    icon: LinkedLogo,
  },
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuScrolled, setMenuScrolled] = useState(false);
  const dispatch = useDispatch();
  const {locale} = useSelector(state => state.projectLocale);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLocaleSwitch = (lang) => {
    dispatch(changeLocale(lang));

    if (lang === 'en') {
      navigate('/en');
    } else {
      navigate('/');
    }
  };

  useScrollPosition(({
    prevPos,
    currPos,
  }) => {
    if (currPos.y < -120) {
      setMenuScrolled(true);
    } else {
      setMenuScrolled(false);
    }
  });

  return (
    <NavbarBox className={menuScrolled ? 'active' : ''}>
      <NavbarWrapper>
        <Logo to={locale === 'en' ? '/en' : '/'}>
          <img src={LogoImage} alt=""/>
        </Logo>
        <MenuWrapper className={menuOpen ? 'active' : ''}>
          <MenuList>
            {hrefs[locale].map((href, i) => (
              <MenuItem key={i}>
                <MenuLink
                  onClick={() => setMenuOpen(false)}
                  activeClassName="current"
                  to={href.slug}
                >
                  {href.title}
                </MenuLink>
              </MenuItem>
            ))}
          </MenuList>
          <MenuOptions>
            <MenuLangBox>
              <MenuList>
                {langs.map((lang, i) => (
                  <MenuItem key={`${lang.title}_${i}`}>
                    <MenuLink to={lang.slug} activeClassName="current" onClick={() => handleLocaleSwitch(lang.title)}>
                     <span className={locale === lang.title ? 'current' : ''}>{lang.title}</span>
                    </MenuLink>
                  </MenuItem>
                ))}
              </MenuList>
            </MenuLangBox>
            <MenuSocials>
              <MenuList>
                {socials.map((social, i) => (
                  <MenuItem key={i}>
                    <StyledSocials target="_blank" href={social.slug}>
                      <img src={social.icon} alt=""/>
                    </StyledSocials>
                  </MenuItem>
                ))}
              </MenuList>
            </MenuSocials>
          </MenuOptions>
        </MenuWrapper>
        <Hamburger
          className={menuOpen ? 'active' : ''}
          onClick={() => handleClick()}
        >
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Hamburger>
      </NavbarWrapper>
    </NavbarBox>
  );
};

export {Navbar};
