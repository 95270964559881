import styled from 'styled-components';
import MenuBg from '../../assets/menu_bg.png';
import {Link} from 'gatsby';

export const Logo = styled(Link)`
    display: inline-block;
    width: 230px;
    transition: 0.5s width ease-in-out;
    outline: none;

    &:focus {
        outline: none;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    @media screen and (max-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        width: 180px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        width: 170px;
    }
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.tabletSmall}) {
        width: 160px;
    }
`;
export const NavbarBox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 10;
    height: 130px;
    transition: 0.4s all ease;
    display: flex;
    align-items: center;

    &.active {
        height: 100px;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
        @media screen and (max-width: ${props =>
                props.theme.breakpoints.laptopStandard}) {
            height: 70px !important;
            ${Logo} {
                width: 180px;
            }
        }
    }

    @media screen and (max-width: ${props =>
            props.theme.breakpoints.laptopStandard}) {
        height: 100px !important;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        height: 70px !important;
    }
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.tabletSmall}) {
        height: 70px !important;
    }
`;

export const NavbarWrapper = styled.div`
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s all ease;
`;
export const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 230px);
    padding-left: 3.125vw;
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        width: calc(100% - 180px);
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        position: fixed;
        top: 70px;
        left: 0;
        padding: 40px 20px 80px 20px;
        width: 100vw;
        height: calc(100vh - 70px);
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        background-image: url(${MenuBg});
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 25%;
        transform: translateX(-120%);
        transition: 0.4s all ease-in-out;
        &.active {
            transform: translateX(0%);
        }
    }
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.tabletSmall}) {
        top: 60px;
        height: calc(100vh - 60px);
        background-image: none;
    }
`;

export const MenuOptions = styled.div`
    display: flex;
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        flex-direction: column;
    }
`;

export const MenuLangBox = styled.div`
    ul {
        flex-direction: row !important;

        li {
            margin: 0 10px;

            a {
                text-transform: uppercase;
                font-size: ${props => props.theme.font.desktop.link.size} !important;
            }
        }

        @media screen and (max-width: ${props =>
                props.theme.breakpoints.tabletBig}) {
            justify-content: center;
        }
    }
`;

export const MenuSocials = styled.div`
    margin-left: 30px;

    ul {
        flex-direction: row !important;

        li {
            margin: 0 10px;

            a {
                font-size: ${props => props.theme.font.desktop.link.size} !important;

                svg {
                    path {
                        transition: 0.4s all ease;
                    }

                    &:hover {
                        path {
                            fill: ${props => props.theme.color.black};
                        }
                    }
                }
            }
        }

        @media screen and (max-width: ${props =>
                props.theme.breakpoints.tabletBig}) {
            justify-content: center;
            margin-top: 10px;
        }
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        margin-left: 0;
    }
`;

export const MenuList = styled.ul`
    display: flex;
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        flex-direction: column;
        align-items: center;
    }
`;
export const MenuItem = styled.li`
    margin: 0 20px;
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        margin: 0 13px;
    }
`;

export const MenuLink = styled(Link)`
    font-size: ${props => props.theme.font.desktop.link.size};
    font-weight: ${props => props.theme.font.desktop.link.weight.extrabold};
    color: ${props => props.theme.color.black};
    line-height: 1.8;
    transition: 0.4s all ease;
    text-decoration: none;
    cursor: pointer;
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        font-size: 14px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        font-size: 30px;
    }

    &.current,
    &:hover {
        color: ${props => props.theme.color.green};
    }
`;

export const StyledSocials = styled.a`
    font-size: ${props => props.theme.font.desktop.link.size};
    font-weight: ${props => props.theme.font.desktop.link.weight.extrabold};
    color: ${props => props.theme.color.black};
    line-height: 1.8;
    transition: 0.4s all ease;
    text-decoration: none;
    cursor: pointer;
    @media screen and (max-width: ${props =>
            props.theme.breakpoints.laptopSmall}) {
        font-size: 14px;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        font-size: 30px;
    }

    &.current,
    &:hover {
        color: ${props => props.theme.color.green};
    }
`;

export const Hamburger = styled.div`
    padding: 10px;
    cursor: pointer;
    display: none;
    @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
        display: block;
    }

    div {
        width: 24px;
        height: 18px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.4s all ease;

        span {
            height: 4px;
            width: 100%;
            display: block;
            background-color: ${props => props.theme.color.green};
            transition: 0.4s all ease;
        }
    }

    &.active {
        div {
            transform: rotate(45deg);

            span:nth-child(1) {
                transform: rotate(90deg) translateX(7px);
            }

            span:nth-child(3) {
                opacity: 0;
            }
        }
    }
`;
