// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-js": () => import("./../../../src/pages/case.js" /* webpackChunkName: "component---src-pages-case-js" */),
  "component---src-pages-case-study-cersanit-js": () => import("./../../../src/pages/case-study/cersanit.js" /* webpackChunkName: "component---src-pages-case-study-cersanit-js" */),
  "component---src-pages-case-study-futuro-viscoplast-js": () => import("./../../../src/pages/case-study/futuro-viscoplast.js" /* webpackChunkName: "component---src-pages-case-study-futuro-viscoplast-js" */),
  "component---src-pages-case-study-index-js": () => import("./../../../src/pages/case-study/index.js" /* webpackChunkName: "component---src-pages-case-study-index-js" */),
  "component---src-pages-case-study-shell-deli-2-go-js": () => import("./../../../src/pages/case-study/shell-deli2go.js" /* webpackChunkName: "component---src-pages-case-study-shell-deli-2-go-js" */),
  "component---src-pages-case-study-vox-js": () => import("./../../../src/pages/case-study/vox.js" /* webpackChunkName: "component---src-pages-case-study-vox-js" */),
  "component---src-pages-en-case-js": () => import("./../../../src/pages/en/case.js" /* webpackChunkName: "component---src-pages-en-case-js" */),
  "component---src-pages-en-case-study-cersanit-js": () => import("./../../../src/pages/en/case-study/cersanit.js" /* webpackChunkName: "component---src-pages-en-case-study-cersanit-js" */),
  "component---src-pages-en-case-study-futuro-viscoplast-js": () => import("./../../../src/pages/en/case-study/futuro-viscoplast.js" /* webpackChunkName: "component---src-pages-en-case-study-futuro-viscoplast-js" */),
  "component---src-pages-en-case-study-index-js": () => import("./../../../src/pages/en/case-study/index.js" /* webpackChunkName: "component---src-pages-en-case-study-index-js" */),
  "component---src-pages-en-case-study-shell-deli-2-go-js": () => import("./../../../src/pages/en/case-study/shell-deli2go.js" /* webpackChunkName: "component---src-pages-en-case-study-shell-deli-2-go-js" */),
  "component---src-pages-en-case-study-vox-js": () => import("./../../../src/pages/en/case-study/vox.js" /* webpackChunkName: "component---src-pages-en-case-study-vox-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-how-we-work-js": () => import("./../../../src/pages/en/how-we-work.js" /* webpackChunkName: "component---src-pages-en-how-we-work-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-our-projects-js": () => import("./../../../src/pages/en/our-projects.js" /* webpackChunkName: "component---src-pages-en-our-projects-js" */),
  "component---src-pages-en-terms-js": () => import("./../../../src/pages/en/terms.js" /* webpackChunkName: "component---src-pages-en-terms-js" */),
  "component---src-pages-en-who-are-we-js": () => import("./../../../src/pages/en/who-are-we.js" /* webpackChunkName: "component---src-pages-en-who-are-we-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-pracujemy-js": () => import("./../../../src/pages/jak-pracujemy.js" /* webpackChunkName: "component---src-pages-jak-pracujemy-js" */),
  "component---src-pages-kim-jestesmy-js": () => import("./../../../src/pages/kim-jestesmy.js" /* webpackChunkName: "component---src-pages-kim-jestesmy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasze-projekty-js": () => import("./../../../src/pages/nasze-projekty.js" /* webpackChunkName: "component---src-pages-nasze-projekty-js" */),
  "component---src-pages-warunki-js": () => import("./../../../src/pages/warunki.js" /* webpackChunkName: "component---src-pages-warunki-js" */),
  "component---src-templates-projects-en-js": () => import("./../../../src/templates/projects-en.js" /* webpackChunkName: "component---src-templates-projects-en-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

