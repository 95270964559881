import React from "react"
import { IconWrapper, IconPath, IconRectStroke } from "shared/Styles/Icons"

const LinkedIn = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconRectStroke
      color={color}
      x="1"
      y="1"
      width="38"
      height="38"
      rx="4"
      stroke="white"
      strokeWidth="2"
    />
    <IconPath
      color={color}
      d="M12.3928 10.3525C11.1585 10.3525 10.3516 11.2295 10.3516 12.3822C10.3516 13.5094 11.1345 14.4114 12.3454 14.4114H12.3689C13.6272 14.4114 14.4104 13.5094 14.4104 12.3822C14.3869 11.2295 13.6272 10.3525 12.3928 10.3525Z"
      fill="white"
    />
    <IconPath
      color={color}
      d="M10.3516 15.7646H14.4104V27.9411H10.3516V15.7646Z"
      fill="white"
    />
    <IconPath
      color={color}
      d="M24.7013 15.7646C22.5493 15.7646 21.1064 17.7693 21.1064 17.7693V16.0439H17.1172V27.9411H21.1061V21.2972C21.1061 20.9416 21.1321 20.5864 21.2375 20.3321C21.5259 19.6219 22.1822 18.8861 23.2842 18.8861C24.7276 18.8861 25.3049 19.9771 25.3049 21.5765V27.9411H29.2937V21.1195C29.2937 17.4651 27.3256 15.7646 24.7013 15.7646Z"
      fill="white"
    />
  </IconWrapper>
)

export { LinkedIn }
