import styled from "styled-components"

export const GlobalWrapper = styled.div`
  width: 100%;
  padding-top: 130px;
  /* overflow-x: hidden; */
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding-top: 100px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    padding-top: 70px;
  }
`
export const PageWrapper = styled.div`
  width: 1250px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
    padding: 0 30px;
  }
`
export const OverflowWrapper = styled.main`
  position: relative;
  max-width: 100vw;
  width: 100%;
`
