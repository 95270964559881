import React from 'react';
import Map from '../../components/Map/Map';

import {
  FooterAddressBox,
  FooterContent,
  FooterMenu,
  FooterMenuLi,
  FooterMenuLink,
  FooterMenuList,
  FooterMenuWrapper,
  FooterRealisation,
  FooterSocials,
  FooterSocialLink,
  FooterTitle,
  FooterWrapper,
  MapWrapper,
  RelImage,
  RelText,
} from './styles.js';
import IVA_Logo from '../../assets/iva_logo.png';

import {Facebook, LinkedIn} from 'components/Icons';
import {useSelector} from 'react-redux';
import {hrefs} from '../../data/footer';

const socials = [
  {
    title: 'facebook',
    slug: 'https://www.facebook.com/Art-Progres-483804225063906',
    icon:
      <Facebook height={'40px'}/>,
  },
  {
    title: 'LinkeIn',
    slug: 'https://www.linkedin.com/company/art-progres/',
    icon:
      <LinkedIn height={'40px'}/>,
  },
];

const data_text = {
  title: 'Kontakt',
  titleEn: 'Contact',
  address: 'ART-PROGRES Polska Sp. z o.o.',
  street: 'Wola Ociecka 87D',
  city: '39-104 Ocieka',
  phone: '+48 17 586 41 27',
  mail: 'kontakt@artprogres.com.pl',
  fb: 'facebook.com',
  in: 'linkedin.com',
};

const Footer = () => {
  const {locale} = useSelector(state => state.projectLocale);

  return (
    <>
      <FooterContent>
        <MapWrapper>
          <Map/>
        </MapWrapper>

        <FooterWrapper>
          <FooterTitle>{locale === 'pl' ? data_text.title : data_text.titleEn}</FooterTitle>
          <FooterAddressBox>
            {data_text.address}
            <br/>
            {data_text.street}
            <br/>
            {data_text.city}
            <br/>
            <a href={`tel:${data_text.phone}`}>
              <span>t:</span>
              {data_text.phone}
            </a>
            <br/>

            <a href={`mailto:${data_text.mail}`}>
              <span>m:</span>
              {data_text.mail}
            </a>
            <br/>
          </FooterAddressBox>
          <FooterSocials>
            {socials.map((social, i) => (
              <FooterSocialLink key={i} target="_blank" href={social.slug}>
                {social.icon}
              </FooterSocialLink>
            ))}
          </FooterSocials>
        </FooterWrapper>
      </FooterContent>
      <FooterMenu>
        <FooterMenuWrapper>
          <FooterMenuList>
            {hrefs[locale].map((href, i) => (
              <FooterMenuLi key={i}>
                <FooterMenuLink to={href.slug}>{href.title}</FooterMenuLink>
              </FooterMenuLi>
            ))}
          </FooterMenuList>
          <FooterRealisation
            href="https://interactivevision.pl/"
            target="_blank"
            title="Interactive Vision"
          >
            <RelText>designed by:</RelText>
            <RelImage src={IVA_Logo}/>
          </FooterRealisation>
        </FooterMenuWrapper>
      </FooterMenu>
    </>
  );
};

export {Footer};
