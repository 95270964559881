import {CHANGE_LOCALE} from './consts';

export const initialState = {
  locale: 'pl',
};

export const projectLocale = (state = initialState, {
  type,
  payload,
}) => {
  switch (type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: payload,
      };
    default:
      return state;
  }
};
