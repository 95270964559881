import styled from "styled-components"
import { Link } from "gatsby"

import StrapsBg from "src/assets/img/straps.svg"

export const FooterContent = styled.div`
  margin-top: 130px;
  width: 100%;
  padding: 110px 0 70px 0;
  background-color: ${props => props.theme.color.black};
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 28px;
    background-image: url(${StrapsBg});
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 80px;
    padding-bottom: 0;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    padding-left: 40px;
  }
`

export const FooterWrapper = styled.div`
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  font-size: 19px;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    font-size: 14px;
    padding-left: 20px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    padding-left: 0;
    margin-bottom: 40px;
    font-size: 12px;
  }
`

export const FooterAddressBox = styled.div`
  line-height: 1.9;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
    transition: 0.4s all ease;
    display: inline-block;
    span {
      color: ${props => props.theme.color.green};
      display: inline-block;
      margin-right: 5px;
    }
    &:hover {
      color: ${props => props.theme.color.green};
    }
  }
`

export const FooterTitle = styled.div`
  color: ${props => props.theme.color.green};
  font-weight: 900;
  margin-bottom: 35px;
`
export const MapWrapper = styled.div`
  width: 47vw;
  height: 550px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    width: 57vw;
    height: 450px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    position: relative;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 350px;
    transform: translateY(40px);
  }
`
export const FooterRealisation = styled.a`
  display: flex;
  align-items: center;
  @media screen and (max-width: 861px) {
    margin-top: 40px;
  }
`

export const FooterSocials = styled.div`
  padding-top: 50px;
  padding-bottom: 10px;
  svg {
    &:hover {
      path {
        fill: ${props => props.theme.color.green};
      }
    }
  }
`

export const FooterSocialLink = styled.a`
  display: inline-block;
  &:not(:last-child) {
    margin-right: 40px;
  }
`

export const RelImage = styled.img`
  width: 103px;
  height: auto;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    width: 78px;
  }
`

export const RelText = styled.span`
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-right: 17px;
  font-weight: 900;
`

export const FooterMenu = styled.div`
  background-color: ${props => props.theme.color.lightBlack};
  padding: 100px 0 80px 0;
`

export const FooterMenuList = styled.ul`
  display: flex;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    flex-wrap: wrap;
  }
`

export const FooterMenuLi = styled.li`
  &:not(:last-child) {
    margin-right: 40px;
  }
  text-align: center;
  width: 100%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    &:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }
`
export const FooterMenuLink = styled(Link)`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  transition: 0.4s all ease;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: ${props => props.theme.color.green};
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopSmall}) {
    font-size: 12px;
  }
`

export const FooterMenuWrapper = styled.div`
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 861px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`
