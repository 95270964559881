import {useEffect} from 'react';
import {changeLocale} from '../../store/locale';
import {useDispatch} from 'react-redux';

export const useLocale = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hrefs = window.location.href.replace(/\/?(#.*)?$/, '').split('/');

      if (hrefs.some(element => element === 'en')) {
        dispatch(changeLocale('en'));
      } else {
        dispatch(changeLocale('pl'));
      }
    }
  }, [dispatch]);
};
