import { CHANGE_FILTER } from "./consts"

export const initialState = {
  filter: "",
}

export const projectsFilter = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_FILTER:
      return {
        ...state,
        filter: payload,
      }
    default:
      return state
  }
}
