import React from "react"
import {
  IconWrapper,
  IconPathStroke,
  IconCircleStroke,
} from "shared/Styles/Icons"

const ArrowLeftCircle = ({ width, height, color }) => (
  <IconWrapper
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <IconPathStroke
      d="M17.8984 11.9327L12.4747 16.0005L17.8984 20.0683"
      color={color}
      stroke="white"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <IconCircleStroke
      color={color}
      cx="16"
      cy="16"
      r="15"
      stroke="white"
      strokeWidth="1"
    />
  </IconWrapper>
)

export { ArrowLeftCircle }
