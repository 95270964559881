export const defaultTheme = {
  color: {
    black: "#383833",
    lightBlack: "#4A4A44",
    gray: "#D7D7D7",
    green: "#ABC539",
    lightGreen: "#C0DD41",
    lime: "#C0DD41",
    grayText: "#7A7A7A",
    white: "#fff",
  },
  font: {
    desktop: {
      h1: {
        size: "75px",
        weight: 500,
      },
      h2: {
        size: "42px",
        weight: 500,
      },
      h3: {
        size: "30px",
        weight: {
          regular: 300,
          bold: 500,
        },
      },
      h4: {
        size: "24px",
        weight: {
          regular: 300,
          bold: 500,
        },
      },
      h5: {
        size: "16px",
        weight: {
          regular: 300,
          bold: 500,
        },
      },
      link: {
        size: "14px",
        weight: {
          extrabold: 700,
        },
      },
    },
    mobile: {
      h1: {
        size: "40px",
        weight: 500,
      },
      h2: {
        size: "30px",
        weight: 500,
      },
      h3: {
        size: "24px",
        weight: {
          regular: 300,
          bold: 500,
        },
      },
      h4: {
        size: "14px",
        weight: 500,
      },
      h5: {
        size: "12px",
        weight: 500,
      },
    },
  },
  breakpoints: {
    phoneSmall: "374px",
    phoneBig: "601px",
    tabletSmall: "769px",
    tabletBig: "1025px",
    tablet: "861px",
    laptopSmall: "1281px",
    laptopStandard: "1441px",
    laptopBig: "1601px",
  },
}
